import styled from "styled-components";

export const Foot = styled.footer`
  width: 100%;
  background-color: #000000e3;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FootEnterprise = styled.h2`
  color: #00ddff;
`